import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box
} from '@mui/material';
import { useStore } from "../../constants/store/store";

const WordleLeaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [dailyPoints, setDailyPoints] = useState(0);
  const [todayDate] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const loadLeaderboard = () => {
      const savedData = localStorage.getItem('wordleLeaderboard');
      if (savedData) {
        setLeaderboardData(JSON.parse(savedData));
      }
    };

    const calculateDailyPoints = () => {
      const completions = localStorage.getItem('wordleCompletions');
      if (completions) {
        const data = JSON.parse(completions);
        if (data.date === todayDate) {
          let points = 0;
          if (data.easy) points += 100;
          if (data.medium) points += 200;
          if (data.hard) points += 400;
          setDailyPoints(points);
        }
      }
    };

    loadLeaderboard();
    calculateDailyPoints();
  }, [todayDate]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Wordle Leaderboard
        </Typography>
        <Typography variant="h6" color="primary">
          Today's Points: {dailyPoints}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          Points System: Easy (+100) | Medium (+200) | Hard (+400)
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Player</TableCell>
              <TableCell align="right">Total Points</TableCell>
              <TableCell align="right">Games Completed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboardData.map((player, index) => (
              <TableRow
                key={player.id}
                sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{player.name}</TableCell>
                <TableCell align="right">{player.points}</TableCell>
                <TableCell align="right">{player.gamesCompleted}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default WordleLeaderboard;