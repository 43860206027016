import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import SignupPage from "./pages/auth/SignupPage";
import ForgotPWPage from "./pages/auth/ForgotPWPage";
import ActionPage from "./pages/auth/ActionPage";
import ChatPage from "./pages/chat/ChatPage";
import LandingPage from "./pages/landing/LandingPage";
import PersonalizePage from "./pages/landing/PersonalizePage";
import DashboardPage from "./pages/flashcards/DashboardPage";
import { useStore } from "./constants/store/store";
import FlashCardsPage from "./pages/flashcards/FlashcardsPage";
import NewsSearch from "./pages/news/newsPage.js";
import Wordle from "./pages/wordle/wordlePage.js";
import WordlePractice from "./pages/wordle/wordlePracticePage.js";
import WordleLeaderboard from "./pages/wordle/leaderboard.js";

function App() {
    const isAuthorized = useStore((state) => state.isAuthorized);
    return (
        <div className="App" style={{ height: "100vh", width: "100%" }}>
            {!isAuthorized ? (
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/forgotPassword" element={<ForgotPWPage />} />
                    <Route path="/action" element={<ActionPage />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/home" element={<LandingPage />} />
                    <Route path="/personalize" element={<PersonalizePage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/wordle" element={<Wordle />} />
                    <Route path="/wordlepractice" element={<WordlePractice />} />
                    <Route path="/news" element={<NewsSearch />} />
                    <Route path="/chats" element={<ChatPage />} />
                    <Route path="/action" element={<ActionPage />} />
                    <Route path="/flashcards" element={<DashboardPage />} />
                    <Route path="/study" element={<FlashCardsPage />} />
                    <Route path="/wordle-leaderboard" element={<WordleLeaderboard />} />
                </Routes>
            )}
            <ToastContainer />
        </div>
    );
}



export default App;
