import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

// Component to handle user statistics
const UserStatsHandler = ({ user, gameWon, difficulty }) => {
  useEffect(() => {
    if (gameWon && user) {
      updateLeaderboard();
    }
  }, [gameWon]);

  const updateLeaderboard = () => {
    // Points system based on difficulty
    const points = {
      easy: 100,
      medium: 200,
      hard: 400
    };

    // Get current date for daily tracking
    const today = new Date().toLocaleDateString();

    // Get or initialize completions tracking
    const completions = JSON.parse(localStorage.getItem('wordleCompletions') || '{}');
    
    // Reset completions if it's a new day
    if (completions.date !== today) {
      completions.date = today;
      completions.easy = false;
      completions.medium = false;
      completions.hard = false;
    }

    // Mark this difficulty as completed for today
    completions[difficulty] = true;
    localStorage.setItem('wordleCompletions', JSON.stringify(completions));

    // Get or initialize leaderboard
    const leaderboard = JSON.parse(localStorage.getItem('wordleLeaderboard') || '[]');
    
    // Find or create player entry
    let playerEntry = leaderboard.find(p => p.id === user.id);
    
    if (!playerEntry) {
      playerEntry = {
        id: user.id,
        name: user.name,
        points: 0,
        gamesCompleted: 0,
        lastPlayed: today,
        stats: {
          easy: { wins: 0, attempts: 0 },
          medium: { wins: 0, attempts: 0 },
          hard: { wins: 0, attempts: 0 }
        },
        streak: {
          current: 0,
          best: 0,
          lastWinDate: null
        }
    };
      leaderboard.push(playerEntry);
    }

    // Update streak
    const lastWinDate = new Date(playerEntry.streak?.lastWinDate || 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (lastWinDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
      playerEntry.streak.current += 1;
      playerEntry.streak.best = Math.max(playerEntry.streak.current, playerEntry.streak.best);
    } else if (lastWinDate.toLocaleDateString() !== today) {
      playerEntry.streak.current = 1;
    }
    playerEntry.streak.lastWinDate = today;

    // Update player stats
    playerEntry.points += points[difficulty];
    playerEntry.gamesCompleted += 1;
    playerEntry.lastPlayed = today;
    playerEntry.stats[difficulty].wins += 1;
    playerEntry.stats[difficulty].attempts += 1;

    // Sort leaderboard by points
    const sortedLeaderboard = leaderboard.sort((a, b) => b.points - a.points);

    // Save updated leaderboard
    localStorage.setItem('wordleLeaderboard', JSON.stringify(sortedLeaderboard));
  };

  return null; // This component doesn't render anything
};

// Component to display player statistics
export const PlayerStats = ({ user }) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (user) {
      const playerStats = getPlayerStats(user.id);
      setStats(playerStats);
    }
  }, [user]);

  if (!stats) return null;

  const calculateWinRate = (difficulty) => {
    const { wins, attempts } = stats.stats[difficulty];
    if (attempts === 0) return '0%';
    return `${Math.round((wins / attempts) * 100)}%`;
  };

  return (
    <Box sx={{ 
      backgroundColor: 'white',
      padding: 3,
      borderRadius: 2,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      minWidth: '250px'
    }}>
      <Typography variant="h6" gutterBottom>{user.name}'s Stats</Typography>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" color="primary">Rank #{stats.rank}</Typography>
        <Typography>Total Points: {stats.points}</Typography>
        <Typography>Games Completed: {stats.gamesCompleted}</Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom>Current Streak: {stats.streak?.current || 0}</Typography>
        <Typography variant="subtitle2" gutterBottom>Best Streak: {stats.streak?.best || 0}</Typography>
      </Box>

      <Typography variant="subtitle2" gutterBottom>Win Rates:</Typography>
      <Box sx={{ pl: 1 }}>
        <Typography>Easy: {calculateWinRate('easy')}</Typography>
        <Typography>Medium: {calculateWinRate('medium')}</Typography>
        <Typography>Hard: {calculateWinRate('hard')}</Typography>
      </Box>
    </Box>
  );
};

// Helper function to get player statistics
export const getPlayerStats = (userId) => {
  const leaderboard = JSON.parse(localStorage.getItem('wordleLeaderboard') || '[]');
  const player = leaderboard.find(p => p.id === userId);
  
  if (!player) return {
    points: 0,
    rank: 'N/A',
    gamesCompleted: 0,
    stats: {
      easy: { wins: 0, attempts: 0 },
      medium: { wins: 0, attempts: 0 },
      hard: { wins: 0, attempts: 0 }
    },
    streak: {
      current: 0,
      best: 0,
      lastWinDate: null
    }
  };

  const rank = leaderboard.findIndex(p => p.id === userId) + 1;
  
  return {
    ...player,
    rank
  };
};

// Get daily completions
export const getDailyCompletions = () => {
  const completions = JSON.parse(localStorage.getItem('wordleCompletions') || '{}');
  const today = new Date().toLocaleDateString();
  
  if (completions.date !== today) {
    return {
      date: today,
      easy: false,
      medium: false,
      hard: false
    };
  }
  
  return completions;
};

// Get full leaderboard data
export const getLeaderboard = () => {
  return JSON.parse(localStorage.getItem('wordleLeaderboard') || '[]');
};

export default UserStatsHandler;