import React, { useState, useEffect, useRef } from 'react';
import vocabData from '../../assets/vocabs/wordle.json';
import { useStore } from "../../constants/store/store";
import { Container, CssBaseline, Box, Grid, Button } from "@mui/material";
import OptionDialog from "../../components/SetOptionsUI/DifficultyDialog.js";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import UserStatsHandler, { PlayerStats } from './UserStatsHandler';

const styles = {
  wordle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wordleGrid: {
    display: 'grid',
    gridGap: '5px',
  },
  wordleCell: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    border: '2px solid #ccc',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  correct: {
    backgroundColor: 'green',
    color: '#fff',
  },
  present: {
    backgroundColor: 'yellow',
  },
  absent: {
    backgroundColor: '#ccc',
  },
  keyboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  keyboardRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5px',
  },
  key: {
    width: '40px',
    height: '50px',
    margin: '0 2px',
    fontSize: '1rem',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '4px',
    background: '#d3d6da',
    cursor: 'pointer',
  },
};

const Keyboard = ({ usedLetters, onKeyPress }) => {
  const getKeyStyle = (key) => {
    const baseStyle = {
      ...styles.key,
      width: key === 'ENTER' || key === '⌫' ? '65px' : '40px',
      backgroundColor: '#d3d6da',
      color: 'black',
    };

    if (!usedLetters || !usedLetters[key]) return baseStyle;

    const colorStyles = {
      correct: { backgroundColor: 'green', color: 'white' },
      present: { backgroundColor: 'yellow', color: 'black' },
      absent: { backgroundColor: '#ccc', color: 'white' }
    };

    return {
      ...baseStyle,
      ...(colorStyles[usedLetters[key]] || {})
    };
  };

  const rows = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫']
  ];

  return (
    <div style={styles.keyboard}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={styles.keyboardRow}>
          {row.map((key) => (
            <button
              key={key}
              onClick={() => onKeyPress(key)}
              style={getKeyStyle(key)}
            >
              {key}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

const EndGameAlert = ({ open, won, word, definition, onPractice, onSelectDifficulty, setShowEndGamePopup}) => {
  const navigate = useNavigate();
  const [difficultyDialogOpen, setDifficultyDialogOpen] = useState(false);

  const handleGoHome = () => {
    navigate('/home');
  };

  return (
    <>
      <Dialog 
        open={open} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '20px',
          }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant="h5" component="div" fontWeight="bold">
            {won ? "Congratulations! 🎉" : "Nice Try! 💪"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <p>The word was:</p>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {word}
            </Typography>
            
            <Typography 
              sx={{ 
                mb: 3,
                mt: 2,
                textAlign: 'center',
                fontStyle: 'italic',
                color: 'text.secondary',
                padding: '0 16px'
              }}
            >
              {definition}
            </Typography>

            <Typography sx={{ mb: 3 }}>
              {won 
                ? <span>Want to practice more?</span>
                : <span>Want to try again with practice mode?</span>
              }
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', flexWrap: 'wrap' }}>
              <Button
                sx={{
                  backgroundColor: "#4A90E2",
                  color: "#FFFFFF",
                  padding: "0.75rem",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#EFF6FF",
                    color: "#4A90E2",
                  },
                  minWidth: "7rem",
                  borderRadius: "20px",
                }}
                onClick={onPractice}
              >
                Practice Mode
              </Button>
              <Button
                sx={{
                  backgroundColor: "#9C27B0",
                  color: "#FFFFFF",
                  padding: "0.75rem",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#F3E5F5",
                    color: "#9C27B0",
                  },
                  minWidth: "7rem",
                  borderRadius: "20px",
                }}
                onClick={() => setDifficultyDialogOpen(true)}
              >
                Select Difficulty
              </Button>
              <Button
                sx={{
                  backgroundColor: "#509458",
                  color: "#FFFFFF",
                  padding: "0.75rem",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#EFFFEF",
                    color: "#509458",
                  },
                  minWidth: "7rem",
                  borderRadius: "20px",
                }}
                onClick={handleGoHome}
              >
                Go Home
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={difficultyDialogOpen}
        onClose={() => setDifficultyDialogOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: { borderRadius: '20px', padding: '20px' }
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant="h6" component="div" fontWeight="bold">
            Select Difficulty
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
            {['Easy', 'Medium', 'Hard'].map((diff) => (
              <Button
                key={diff}
                sx={{
                  backgroundColor: "#9C27B0",
                  color: "#FFFFFF",
                  padding: "0.75rem",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#F3E5F5",
                    color: "#9C27B0",
                  },
                  minWidth: "7rem",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  onSelectDifficulty(diff.toLowerCase());
                  setDifficultyDialogOpen(false);
                  setShowEndGamePopup(false);
                  
                }}
              >
                {diff}
              </Button>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );}

const Wordle = () => {
  const navigate = useNavigate();
  const handleNavigateToPractice = () => {
    navigate('/wordlepractice');
  };
  const [word, setWord] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [definition, setDefinition] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gridSize, setGridSize] = useState(6);
  const [difficulty, setDifficulty] = useState('easy');
  const [difficultyChanged, setDifficultyChanged] = useState(false);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const [usedLetters, setUsedLetters] = useState({});
  const [timeUntilNextWord, setTimeUntilNextWord] = useState('');
  const buttonRef = useRef(null);
  const [showEndGamePopup, setShowEndGamePopup] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  

  const [chosenIndustry, setChosenIndustry] = useState(
    useStore((state) => state.industry)
  );
   // Subscribe to industry changes
   // Subscribe to industry changes
useEffect(() => {
  const industry = useStore.getState().industry;
  if (industry !== chosenIndustry) {
    setChosenIndustry(industry);
    // Clear existing words and timestamps
    localStorage.removeItem('wordleWords');
    localStorage.removeItem('wordleLastWordTime');
    
    // Generate new words specific to the new industry
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    
    const newWords = {
      easy: getRandomWord(industry, 'easy'),
      medium: getRandomWord(industry, 'medium'),
      hard: getRandomWord(industry, 'hard')
    };
    
    localStorage.setItem('wordleLastWordTime', startOfDay.toString());
    saveCurrentWords(newWords);
    
    const currentWord = newWords[difficulty.toLowerCase()];
    if (currentWord) {
      setWord(currentWord.word);
      setDefinition(currentWord.definition);
      setGridSize(difficulty.toLowerCase() === 'hard' ? 7 : 6);
      setGuesses([]);
      setCurrentGuess('');
      setCurrentRow(0);
      setGameOver(false);
      setUsedLetters({});
    }
  }
}, [useStore((state) => state.industry)]);


  // Format time remaining in HH:MM:SS
  const formatTimeRemaining = (milliseconds) => {
    if (milliseconds < 0) return '00:00:00';
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

//for leaderboard
const user = useStore((state) => state.user);


const updatePoints = (difficulty) => {
  const points = {
    easy: 100,
    medium: 200,
    hard: 400
  };

  const today = new Date().toLocaleDateString();
  const completions = localStorage.getItem('wordleCompletions') || '{}';
  const completionData = JSON.parse(completions);

  if (completionData.date !== today) {
    completionData.date = today;
    completionData.easy = false;
    completionData.medium = false;
    completionData.hard = false;
  }

  completionData[difficulty] = true;
  localStorage.setItem('wordleCompletions', JSON.stringify(completionData));

  const leaderboard = JSON.parse(localStorage.getItem('wordleLeaderboard') || '[]');
  
  let playerEntry = leaderboard.find(p => p.id === user.id);
  if (!playerEntry && user) {
    playerEntry = {
      id: user.id,
      name: user.name,
      points: 0,
      gamesCompleted: 0
    };
    leaderboard.push(playerEntry);
  }

  if (playerEntry) {
    playerEntry.points += points[difficulty];
    playerEntry.gamesCompleted += 1;
  }

  localStorage.setItem('wordleLeaderboard', JSON.stringify(
    leaderboard.sort((a, b) => b.points - a.points)
  ));
};


  // Save current words for all difficulties
  const saveCurrentWords = (words) => {
    const now = new Date();
    const dailySeed = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const wordsWithMetadata = {
      ...words,
      seed: dailySeed,
      industry: chosenIndustry // Save the industry with the words
    };
    localStorage.setItem('wordleWords', JSON.stringify(wordsWithMetadata));
  };
  // Get saved words
  const getSavedWords = () => {
    const savedWords = localStorage.getItem('wordleWords');
    return savedWords ? JSON.parse(savedWords) : null;
  };

  const getRandomWord = (category, difficulty) => {
    // Get today's seed or create one
    const now = new Date();
    const todaySeed = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    
    const categoryData = vocabData[category];
    if (!categoryData || !categoryData[0]) {
      console.warn('No data found for category:', category);
      return null;
    }
  
    const difficultyWords = categoryData[0][difficulty.toLowerCase()];
    if (!difficultyWords || difficultyWords.length === 0) {
      console.warn('No words found for difficulty:', difficulty);
      return null;
    }
  
    // Filter words based on difficulty level length requirements
    const filteredWords = difficultyWords.filter(wordObj => {
      const word = Object.keys(wordObj)[0];
      const length = word.length;
      switch (difficulty.toLowerCase()) {
        case 'easy':
          return length <= 5;
        case 'medium':
          return length === 6;
        case 'hard':
          return length === 7;
        default:
          return true;
      }
    });
    
  
    if (filteredWords.length === 0) {
      console.warn('No words of appropriate length found for:', difficulty);
      return null;
    }
  
    // Create a unique hash for each combination of date, category, and difficulty
    const getHash = (str) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
      }
      return Math.abs(hash);
    };
  
    // Combine date, category, and difficulty to create a unique seed
    const uniqueString = `${todaySeed}-${category}-${difficulty}`;
    const uniqueHash = getHash(uniqueString);
    
    // Use the unique hash to select a word
    const randomIndex = uniqueHash % filteredWords.length;
    const wordObj = filteredWords[randomIndex];
    const word = Object.keys(wordObj)[0];
    const definition = Object.values(wordObj)[0];
  
    return { 
      word: word.toUpperCase(), 
      definition 
    };
  };
const handleDifficultyChange = (newDifficulty) => {
  const savedWords = getSavedWords();
  const newDifficultyLevel = newDifficulty.toLowerCase();
  setDifficulty(newDifficultyLevel);
  
  if (savedWords) {
    const newWord = savedWords[newDifficultyLevel];
    if (newWord) {
      setWord(newWord.word);
      setDefinition(newWord.definition);
    } else {
      // If no saved word for this difficulty, generate a new one
      const generatedWord = getRandomWord(chosenIndustry, newDifficultyLevel);
      if (generatedWord) {
        const newWords = { ...savedWords, [newDifficultyLevel]: generatedWord };
        saveCurrentWords(newWords);
        setWord(generatedWord.word);
        setDefinition(generatedWord.definition);
      }
    }
  } else {
    // If no saved words at all, generate new ones
    const newWords = {
      easy: getRandomWord(chosenIndustry, 'easy'),
      medium: getRandomWord(chosenIndustry, 'medium'),
      hard: getRandomWord(chosenIndustry, 'hard')
    };
    saveCurrentWords(newWords);
    const currentWord = newWords[newDifficultyLevel];
    if (currentWord) {
      setWord(currentWord.word);
      setDefinition(currentWord.definition);
    }
  }

  setGridSize(newDifficultyLevel === 'hard' ? 7 : 6);
  setDifficultyChanged(true);
  setShowDifficultyModal(false);
  setGuesses([]);
  setCurrentGuess('');
  setCurrentRow(0);
  setGameOver(false);
  setUsedLetters({});

  if (buttonRef.current) {
    buttonRef.current.blur();
  }
  window.blur();
};

  // Initialize words and timer
  useEffect(() => {
    const initializeGame = () => {
      const savedWords = getSavedWords();
      const lastWordTime = localStorage.getItem('wordleLastWordTime');
      const savedIndustry = savedWords?.industry;
  
      // If industry changed or no saved words, initialize new words
      if (!savedWords || savedIndustry !== chosenIndustry) {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        
        const newWords = {
          easy: getRandomWord(chosenIndustry, 'easy'),
          medium: getRandomWord(chosenIndustry, 'medium'),
          hard: getRandomWord(chosenIndustry, 'hard')
        };
        
        localStorage.setItem('wordleLastWordTime', startOfDay.toString());
        saveCurrentWords(newWords);
        
        const currentWord = newWords[difficulty.toLowerCase()];
        setWord(currentWord.word);
        setDefinition(currentWord.definition);
      } else {
        // Use existing words if industry matches
        const currentWord = savedWords[difficulty.toLowerCase()];
        setWord(currentWord.word);
        setDefinition(currentWord.definition);
      }
      
      setGridSize(difficulty.toLowerCase() === 'hard' ? 7 : 6);
      setDifficultyChanged(false);
      setUsedLetters({});
      setGuesses([]);
      setCurrentGuess('');
      setCurrentRow(0);
      setGameOver(false);
    };
  
    initializeGame();
  }, [difficulty, chosenIndustry]);
  // Update timer
  useEffect(() => {
    const updateTimer = () => {
      const lastWordTime = parseInt(localStorage.getItem('wordleLastWordTime'));
      if (!lastWordTime) return;

      // Calculate the next word time (next midnight)
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const timeRemaining = tomorrow.getTime() - now.getTime();

      if (timeRemaining <= 0) {
        setTimeUntilNextWord('00:00:00');
        // Generate new words and update timestamp
        const newWords = {
          easy: getRandomWord(chosenIndustry, 'easy'),
          medium: getRandomWord(chosenIndustry, 'medium'),
          hard: getRandomWord(chosenIndustry, 'hard')
        };
        
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        localStorage.setItem('wordleLastWordTime', startOfDay.toString());
        saveCurrentWords(newWords);
        
        const currentWord = newWords[difficulty.toLowerCase()];
        setWord(currentWord.word);
        setDefinition(currentWord.definition);
        setGridSize(difficulty.toLowerCase() === 'hard' ? 7 : 6);
        setGuesses([]);
        setCurrentGuess('');
        setCurrentRow(0);
        setGameOver(false);
        setUsedLetters({});
      } else {
        setTimeUntilNextWord(formatTimeRemaining(timeRemaining));
      }
    };

    updateTimer();
    const timerInterval = setInterval(updateTimer, 1000);
    return () => clearInterval(timerInterval);
  }, [chosenIndustry]);

  const handleKeyPress = async (key) => {
    if (gameOver) return;

    if (key === 'ENTER') {
      if (currentGuess.length === word.length) {
        buttonRef.current.blur();
        const isValidWord = await checkWordValidity(currentGuess);
        if (!isValidWord) {
          alert('Not a valid word!');
          return;
        }

        setGuesses([...guesses, currentGuess]);
        setCurrentGuess('');
        setCurrentRow(currentRow + 1);

        setUsedLetters((prevUsedLetters) => {
          const newUsedLetters = { ...prevUsedLetters };
          const guessArray = currentGuess.split('');
          const wordArray = word.split('');
          
          guessArray.forEach((letter, i) => {
            if (letter === wordArray[i]) {
              newUsedLetters[letter] = 'correct';
            }
          });
          
          guessArray.forEach((letter, i) => {
            if (!newUsedLetters[letter] || newUsedLetters[letter] !== 'correct') {
              if (wordArray.includes(letter)) {
                newUsedLetters[letter] = 'present';
              } else {
                newUsedLetters[letter] = 'absent';
              }
            }
          });
          
          return newUsedLetters;
        });

        if (currentGuess === word) {
          setGameOver(true);
          setGameWon(true);
          setShowEndGamePopup(true);
          updatePoints(difficulty); // Add this line
        } else if (currentRow === gridSize - 1) {
          setGameOver(true);
          setGameWon(false);
          setShowEndGamePopup(true);
        }
      }
    } else if (key === '⌫') {
      setCurrentGuess(currentGuess.slice(0, -1));
    } else if (currentGuess.length < word.length) {
      setCurrentGuess(currentGuess + key);
    }
  };

  // Keyboard event handlers
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleKeyPress('ENTER');
    } else if (event.key === 'Backspace') {
      handleKeyPress('⌫');
    } else if (event.key.match(/^[a-zA-Z]$/)) {
      handleKeyPress(event.key.toUpperCase());
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentGuess, word, currentRow, gameOver]);

  const checkWordValidity = async (guess) => {
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${guess.toLowerCase()}`);
      const data = await response.json();
      return data.title !== 'No Definitions Found';
    } catch (error) {
      console.error('Error checking word validity:', error);
      return true; // Allow word if API fails
    }
  };

  const getGridSize = () => ({
    gridTemplateColumns: `repeat(${word.length}, 1fr)`,
    gridTemplateRows: `repeat(${gridSize}, 1fr)`,
  });

  const getLetterStatus = (letter, index, rowIndex) => {
    if (!letter || rowIndex >= guesses.length) return '';

    const guess = guesses[rowIndex];
    const wordArray = word.split('');

    if (letter === wordArray[index]) return 'correct';

    if (wordArray.includes(letter)) {
      const letterCount = wordArray.filter(l => l === letter).length;
      const correctPositions = guess.split('').filter((l, i) => l === letter && wordArray[i] === letter).length;
      if (correctPositions < letterCount) return 'present';
    }

    return 'absent';
  };

  return (
    <div style={styles.wordle}>
      <div style={{ 
        position: 'absolute', 
        top: 20, 
        right: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        {user && <PlayerStats user={user} />}
        <Button
          sx={{
            backgroundColor: "#9C27B0",
            color: "#FFFFFF",
            padding: "0.75rem",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#F3E5F5",
              color: "#9C27B0",
            },
            minWidth: "7rem",
            borderRadius: "20px",
          }}
          onClick={() => navigate('/wordle-leaderboard')}
        >
          Leaderboard
        </Button>
      </div>
   
      <div style={{ marginBottom: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography 
          sx={{ 
            color: '#8e8e8e',
            fontSize: '14px',
            marginBottom: '12px',
            fontWeight: '400'
          }}
        >
          Next set of words in
        </Typography>
        <Typography 
          sx={{ 
            fontFamily: 'Roboto, sans-serif',
            fontSize: '48px',
            fontWeight: '400',
            color: '#444452',
            letterSpacing: '2px',
            lineHeight: 1.2
          }}
        >
          {timeUntilNextWord}
        </Typography>
      </div>
   
      <Button
        ref={buttonRef}
        tabIndex={-1}
        sx={{
          margin: "10px",
          backgroundColor: "#509458",
          color: "#FFFFFF",
          padding: "0.75rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EFFFEF",
            color: "#509458",
          },
          minWidth: "7rem",
          borderRadius: "20px",
        }}
        onClick={() => setShowDifficultyModal(true)}
      >
        {difficulty.toUpperCase()}
      </Button>
   
      {showDifficultyModal && (
        <OptionDialog
          open={showDifficultyModal}
          setOpen={setShowDifficultyModal}
          action={handleDifficultyChange}
          options={["Easy", "Medium", "Hard"]}
          buttonRef={buttonRef}
        />
      )}
   
      <div style={{ ...styles.wordleGrid, ...getGridSize() }}>
        {Array(gridSize).fill().map((_, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {Array(word.length).fill().map((_, cellIndex) => {
              const letter =
                rowIndex < guesses.length
                  ? guesses[rowIndex][cellIndex] || ''
                  : rowIndex === currentRow
                  ? currentGuess[cellIndex] || ''
                  : '';
   
              return (
                <div
                  key={cellIndex}
                  style={{
                    ...styles.wordleCell,
                    ...(rowIndex < guesses.length ? styles[getLetterStatus(letter, cellIndex, rowIndex)] || {} : {}),
                  }}
                >
                  {letter}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
      {user && (
      <UserStatsHandler 
        user={user}
        gameWon={gameWon}
        difficulty={difficulty}
      />
    )}
      <EndGameAlert 
        open={showEndGamePopup}
        won={gameWon}
        word={word}
        definition={definition}
        onPractice={handleNavigateToPractice}
        showDifficultyModal={showDifficultyModal}
        setShowDifficultyModal={setShowDifficultyModal}
        onSelectDifficulty={handleDifficultyChange}
        setShowEndGamePopup={setShowEndGamePopup}
      />
   
      <Keyboard usedLetters={usedLetters} onKeyPress={handleKeyPress} />
      
      <Button
        sx={{
          margin: "20px",
          backgroundColor: "#4A90E2",
          color: "#FFFFFF",
          padding: "0.75rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EFF6FF",
            color: "#4A90E2",
          },
          minWidth: "7rem",
          borderRadius: "20px",
        }}
        onClick={handleNavigateToPractice}
      >
        Practice Mode
      </Button>
    </div>
   );
  }

export default Wordle;